import { injectable } from 'inversify';
import { ILogger } from './ILogger';

@injectable()
export default class Logger implements ILogger {
    // TODO: set this value based on devtools presence
    // TODO: add timestamp?
    // TODO: add logging level

    constructor() {
        window.logging = window.logging || !import.meta.env.PROD || false;
    }

    log(...args: any[]) {
        if (window.logging) console.log(...args); // eslint-disable-line no-console
    }

    error(...args: any[]) {
        if (window.logging) console.error(...args); // eslint-disable-line no-console
    }

    info(...args: any[]) {
        if (window.logging) console.info(...args); // eslint-disable-line no-console
    }

    warn(...args: any[]) {
        if (window.logging) console.warn(...args); // eslint-disable-line no-console
    }

    // TODO: add other console methods?
}
