import type { OrderDto, OrdersPageDto } from '@/types/webapi';

export const IOrderApiId = Symbol.for('IOrderApi');

export interface IOrderApi {
    /**
     * Fetch certain order for current user.
     */
    getOrder(orderId: string): Promise<OrderDto>;

    /**
     * Fetch certain order by order id and transaction id (used only for anonymous TC orders).
     */
    getOrderByTransactionId(orderId: string, transactionId: string): Promise<OrderDto>;

    /**
     * Fetch orders for current user
     * @param skip Amount of orders to skip
     * @param take Amount of orders to take
     */
    getOrdersPage(skip: number, take: number): Promise<OrdersPageDto>;
}
