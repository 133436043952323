import { injectable } from 'inversify';
import { ShoppingCartDto, AddLineItemRequestDto, LineItemDto, ProductType, TravelCard } from '@/types/webapi';
import { BaseApi } from './BaseApi';
import { AxiosError } from 'axios';
import { ICartApi } from './ICartApi';

/**
 * API service that communicates with the Webshop Cart Web API endpoint.
 */
@injectable()
export default class CartApi extends BaseApi implements ICartApi {
    async getCart() {
        this.logger.log('[CartApi.getCart] Fetching cart');
        return await this.httpGet<ShoppingCartDto>('/api/v1.0/cart');
    }

    async validateCart() {
        this.logger.log('[CartApi.validateCart] Validating cart');
        return await this.httpGet<ShoppingCartDto>('/api/v1.0/cart/validate');
    }

    async clearCart() {
        this.logger.log('[CartApi.clearCart] Clearing cart');
        return await this.httpPost<ShoppingCartDto>('/api/v1.0/cart/clear');
    }

    async setLineItem(lineItem: LineItemDto) {
        this.logger.log('[CartApi.setLineItem] Setting lineItem', lineItem);

        // if lineItem is null, clear cart
        if (!lineItem) {
            this.logger.log('[CartApi.setLineItem] LineItem is null. Clearing cart.');
            return await this.clearCart();
        }

        const requestDto: AddLineItemRequestDto = {
            categoryId: lineItem.categoryId,
            configurationId: lineItem.configurationId,
            amount: lineItem.price,
            productId: lineItem.productId,
            zones: lineItem.zones ? lineItem.zones.map(x => x.id).join(',') : undefined,
            travelCard: lineItem.travelCard as TravelCard,
            isTravelMoney: lineItem.productType === ProductType.TravelMoney
        };

        return await this.httpPost<ShoppingCartDto>('/api/v1.0/cart', requestDto);
    }

    errorHandler(e: AxiosError) {
        switch (e.response && e.response.status) {
            case 404:
                return new Error('errorMessages.cart.notFound');
            default:
                return super.errorHandler(e);
        }
    }
}
