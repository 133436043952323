import type { ShoppingCartDto, LineItemDto } from '@/types/webapi';

export const ICartApiId = Symbol.for('ICartApi');

export interface ICartApi {
    /**
     * Fetch shopping cart for current user.
     */
    getCart(): Promise<ShoppingCartDto>;

    /**
     * Fetch shopping cart for current user, revalidate cart contents
     */
    validateCart(): Promise<ShoppingCartDto>;

    /**
     * Sets the line item value in the shopping cart.
     * @param requestDto Request object
     */
    setLineItem(requestDto: Partial<LineItemDto>): Promise<ShoppingCartDto>;

    /**
     * Clear the shopping cart.
     */
    clearCart(): Promise<ShoppingCartDto>;
}
