import { injectable } from 'inversify';
import { IPaymentApi } from './IPaymentApi';
import { BaseApi } from './BaseApi';
import { AxiosError } from 'axios';
import type { CheckoutStatusDto, InitiatePaymentResponseDto, ProcessPaymentResponseDto } from '@/types/webapi';

@injectable()
export default class PaymentApi extends BaseApi implements IPaymentApi {
    async initPayment(redirectUrl: string) {
        this.logger.log('[PaymentApi.initPayment] Initializing payment', redirectUrl);
        return await this.httpPost<InitiatePaymentResponseDto>('/api/v1.0/payment/transaction', { redirectUrl });
    }

    async getOrderData(transactionId: string) {
        this.logger.log('[PaymentApi.getOrderData] Getting order data', transactionId);
        return await this.httpGet<ProcessPaymentResponseDto>('/api/v1.0/payment/transaction/' + transactionId);
    }

    async processPayment(transactionId: string) {
        this.logger.log('[PaymentApi.processPayment] Processing payment', transactionId);
        return await this.httpPost<ProcessPaymentResponseDto>(`/api/v1.0/payment/transaction/${transactionId}/process`);
    }

    async getStatus(transactionId: string, orderId: string, timeout?: number) {
        this.logger.log('[PaymentApi.getStatus] Getting payment status', transactionId, orderId);
        return await this.httpGet<CheckoutStatusDto>(`/api/v1.0/payment/transaction/status/${orderId}/${transactionId}`, null, timeout);
    }

    async cancelOrder(transactionId: string) {
        this.logger.log('[PaymentApi.cancelOrder] Cancelling order', transactionId);
        return await this.httpPost<ProcessPaymentResponseDto>(`/api/v1.0/payment/transaction/${transactionId}/cancel`);
    }

    errorHandler(e: AxiosError) {
        switch (e.response?.status) {
            case 404:
                return new Error('errorMessages.transaction.notFound');
            case 400:
                return new Error('Unauthorized error');
            default:
                return super.errorHandler(e);
        }
    }
}
