import type { AppConfigDto } from '@/types/webapi';
import { IConfigService } from './IConfigService';
import { inject, injectable } from 'inversify';
import { AxiosInstance, IAxiosId } from '@/plugins/axios';
import { useMainStore } from '@/features/common/stores';

@injectable()
export default class AppConfigService implements IConfigService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    _mainStore: ReturnType<typeof useMainStore>;
    get mainStore(): ReturnType<typeof useMainStore> {
        if (!this._mainStore) {
            this._mainStore = useMainStore();
        }

        return this._mainStore;
    }

    getConfig() {
        return new Promise<AppConfigDto>((resolve, reject) => {
            this.axios
                .get('/api/v1.0/config')
                .then(response => resolve(response.data))
                .catch(e => {
                    this.mainStore.registerError(e);
                    reject(e);
                });
        });
    }
}
