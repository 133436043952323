import type { InitiatePaymentResponseDto, CheckoutStatusDto, ProcessPaymentResponseDto } from '@/types/webapi';

export const IPaymentApiId = Symbol.for('IPaymentApi');

export interface IPaymentApi {
    initPayment(redirectUrl: string): Promise<InitiatePaymentResponseDto>;
    getOrderData(transactionId: string): Promise<ProcessPaymentResponseDto>;
    processPayment(transactionId: string): Promise<ProcessPaymentResponseDto>;
    getStatus(transactionId: string, orderId: string, timeout?: number): Promise<CheckoutStatusDto>;
    cancelOrder(transactionId: string): Promise<ProcessPaymentResponseDto>;
}
