import { injectable } from 'inversify';
import { BaseApi } from './BaseApi';
import type { ITicketApi } from './ITicketApi';
import type { TicketDto, BoatQuayItemDto, TicketGroupDto, ZoneDto } from '@/types/webapi';
import type { AxiosError } from 'axios';

@injectable()
export default class TicketApi extends BaseApi implements ITicketApi {
    async getTickets() {
        this.logger.log('[TicketApi.getTickets] Fetching tickets');
        return await this.httpGet<TicketGroupDto[]>('/api/v1.0/tickets');
    }

    async getTicket(zones: string, configId: string, categoryId: number) {
        this.logger.log('[TicketApi.getTicket] Fetching ticket for', zones, configId, categoryId);
        return await this.httpGet<TicketDto>('/api/v1.0/tickets/single', {
            params: {
                zones,
                configId,
                categoryId
            }
        });
    }

    async getTicketZonePolygons() {
        this.logger.log('[TicketApi.getTicketZonePolygons] Fetching ticket zone polygons');
        return await this.httpGet<ZoneDto[]>('/api/v1.0/tickets/zones');
    }

    async getQuays() {
        this.logger.log('[TicketApi.getQuays] Fetching quay data');
        return await this.httpGet<BoatQuayItemDto[]>('/api/v1.0/tickets/quays');
    }

    errorHandler(e: AxiosError) {
        switch (e.response?.status) {
            case 404:
                return new Error('errorMessages.tickets.notFound');
            default:
                return super.errorHandler(e);
        }
    }
}
