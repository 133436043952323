import { Container } from 'inversify';
import { IConfigService, IConfigServiceId } from '@/services/IConfigService';
import AppConfigService from '@/services/AppConfigService';
import { ax, AxiosInstance, IAxiosId } from './axios';
import { InjectionKey, Plugin, inject } from 'vue';
import { ILogger, ILoggerId } from '@/services/ILogger';
import Logger from '@/services/Logger';
import { ICartApi, ICartApiId } from '@/services/ICartApi';
import CartApi from '@/services/CartApi';
import { IOrderApi, IOrderApiId } from '@/services/IOrderApi';
import OrderApi from '@/services/OrderApi';
import { IPaymentApi, IPaymentApiId } from '@/services/IPaymentApi';
import PaymentApi from '@/services/PaymentApi';
import { ITicketApi, ITicketApiId } from '@/services/ITicketApi';
import TicketApi from '@/services/TicketApi';
import { ITravelCardsApi, ITravelCardsApiId } from '@/services/ITravelCardsApi';
import TravelCardsApi from '@/services/TravelCardsApi';

const __INVERSIFY_SYMBOL__ = Symbol.for('app-inversify-container');
const injectionKey: InjectionKey<Container> = __INVERSIFY_SYMBOL__;

export function createContainer(): Plugin {
    const container = new Container({ defaultScope: 'Singleton' });

    container.bind<AxiosInstance>(IAxiosId).toConstantValue(ax);
    container.bind<IConfigService>(IConfigServiceId).to(AppConfigService);
    container.bind<ILogger>(ILoggerId).to(Logger);
    container.bind<ICartApi>(ICartApiId).to(CartApi);
    container.bind<IOrderApi>(IOrderApiId).to(OrderApi);
    container.bind<IPaymentApi>(IPaymentApiId).to(PaymentApi);
    container.bind<ITicketApi>(ITicketApiId).to(TicketApi);
    container.bind<ITravelCardsApi>(ITravelCardsApiId).to(TravelCardsApi);

    return {
        install(app) {
            app.provide(injectionKey, container);
        }
    };
}

export function useContainer() {
    const _container = inject(injectionKey);
    if (!_container) throw new Error('You must call createContainer() first');

    return _container;
}
