import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import useMainStore from './mainStore';

/**
 * vue-router navigation guard that registers the debug mode flag
 */
export function commonQueryParamMiddleware(to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) {
    const { debugMode } = to.query;

    if (debugMode) {
        const mainStore = useMainStore();
        mainStore.isDebugMode = debugMode === 'true';
    }

    next();
}
