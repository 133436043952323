import { injectable } from 'inversify';
import { BaseApi } from './BaseApi';
import { IOrderApi } from './IOrderApi';
import type { CheckoutStatusDto, OrderDto, OrdersPageDto } from '@/types/webapi';

@injectable()
export default class OrderApi extends BaseApi implements IOrderApi {
    async getOrder(orderId: string) {
        this.logger.log('[OrderApi.getOrder] Fetching order ' + orderId);
        return await this.httpGet<OrderDto>('/api/v1.0/orders/' + orderId);
    }

    async getOrderByTransactionId(orderId: string, transactionId: string) {
        this.logger.log('[OrderApi.getOrderByTransactionId] Fetching order ' +  orderId + ' by transaction id' + transactionId);
        return await this.httpGet<OrderDto>(`/api/v1.0/orders/${orderId}/${transactionId}`);
    }
    async getOrdersPage(skip: number, take: number): Promise<OrdersPageDto> {
        this.logger.log('[OrderApi.getOrdersPage] Fetching orders page, skip: ' + skip + ', take: ' + take);
        return await this.httpGet<OrdersPageDto>('/api/v1.0/orders', { params: { skip, take }});
    }
}
