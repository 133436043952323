import type { BoatQuayItemDto, TicketDto, TicketGroupDto, ZoneDto } from '@/types/webapi';

export const ITicketApiId = Symbol.for('ITicketApi');

export interface ITicketApi {
    /**
     * Fetches all available tickets.
     */
    getTickets(): Promise<TicketGroupDto[]>;

    /**
     * Fetches a matching Ticket based on the given parameters.
     * @param zones List of zone IDs
     * @param configId Configuration ID
     * @param categoryId Category ID
     */
    getTicket(zones: string, configId: string, categoryId: number): Promise<TicketDto>;

    /**
     * Fetches all ticket zone polygons.
     */
    getTicketZonePolygons(): Promise<ZoneDto[]>;

    /**
     * Fetches all quay data.
     */
    getQuays(): Promise<BoatQuayItemDto[]>;
}
