import { useContainer } from '@/plugins/inversify';
import { IConfigService, IConfigServiceId } from '@/services/IConfigService';
import { AppConfigDto } from '@/types/webapi';
import { MenuStructure, useMenuProvider } from '@geta/kolumbus-frontend/composables';
import { isNull, isObject, isUndefined, merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

export interface MainStore {
    ready: boolean;
    isLoading: boolean;
    isDebugMode: boolean | undefined;
    language: string;
    config: AppConfigDto & { supportedLanguages: string[] };
    menuItems: MenuStructure;
    lastKnownError: Error | null;
}

const defaultState = (): MainStore => ({
    ready: false,
    isLoading: false,
    language: document.documentElement.lang || 'no',
    isDebugMode: false,
    config: {
        kolumbusWebsiteBaseUrl: '',
        supportedLanguages: ['no', 'en'],
        googleMapsConfig: {
            apiKey: '',
        },
        silentLoginEnabled: true,
        defaultCulture: 'no',
        orderHistoryPageUrl: {}
    },
    menuItems: {
        baseUrl: '/'
    },
    lastKnownError: null,
});

const useMainStore = defineStore('mainStore', () => {
    const container = useContainer();
    const configService = container.get<IConfigService>(IConfigServiceId);

    const state = reactive(defaultState());
    const menu = computed(() => state.menuItems);

    async function init() {
        try {
            state.isLoading = true;

            // fetch app config
            const config = await configService.getConfig();

            // set up app state
            const supportedLanguages = merge(['no', 'en'], state.menuItems?.supportedLanguages?.map(l => l.code) || []);
            state.config = merge(state.config, config, { supportedLanguages });
            state.language = config.defaultCulture;

            // set up menus
            const menuProvider = useMenuProvider(config.kolumbusWebsiteBaseUrl);
            state.menuItems = await menuProvider.getMenuItems();
        } catch (e) {
            registerError(e);
        } finally {
            state.isLoading = false;
        }
    }

    function reset(newState: MainStore) {
        Object.assign(state, newState);
    }

    function registerError(error: any) {
        state.lastKnownError = isErrorWithMessage(error) ? error : new Error(String(error));
    }

    return {
        ...toRefs(state),
        menu,
        init,
        reset,
        registerError
    };
});

function isErrorWithMessage(error: unknown): error is Error {
    return !isUndefined(error) && !isNull(error) && isObject(error) && 'message' in error && typeof error.message === 'string';
}

export default useMainStore;
